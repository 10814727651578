<template>
  <div>
    <el-card>
      <el-table
        :data="appointmentList"
        stripe
        class="table"
        ref="userTable.multipleSelection"
        header-cell-class-name="table-header"
        border
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>

        <el-table-column
          label="类型"
          prop="type"
          align="center"
        ></el-table-column>
        <el-table-column label="缩略图" prop="thumb_url" align="center">
          <template slot-scope="scope">
            <div class="thumb_box">
              <img :src="scope.row.thumb_url" alt="" />
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="人数"
          prop="views"
          align="center"
        ></el-table-column>
        <el-table-column label="时间" align="center">
          <template slot-scope="scope">
            {{
              $moment(scope.row.create_time * 1000).format(
                "YYYY-MM-DD HH:mm:ss"
              )
            }}
          </template>
        </el-table-column>

        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              circle
              @click="editGoods(scope.row)"
              size="small"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 约课编辑 -->
      <el-dialog
        title="约课编辑"
        :visible.sync="addDialog"
        :center="true"
        width="40%"
        @close="closed"
        :close-on-click-modal="false"
      >
        <el-form :model="addForm" ref="addForm">
          <el-form-item el-form-item label="类型" label-width="100px">
            <el-select
              v-model="addForm.type"
              placeholder="请选择类型"
              clearable
              disabled
            >
              <el-option
                :label="item.value"
                :value="item.key"
                v-for="item in appointmentType"
                :key="item.key"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item el-form-item label="标签" label-width="100px">
            <div class="addtopic">
              <div></div>
              <el-button type="primary" @click="addTopicOptions"
                >添加标签</el-button
              >
            </div>
            <div
              class="add_box"
              v-for="(item, index) in addForm.label"
              :key="index"
            >
              <el-input
                v-model="item.value"
                placeholder="请输入标签"
                style="width: 300px"
              ></el-input>
            </div>
          </el-form-item>

          <el-form-item el-form-item label="缩略图" label-width="100px">
            <el-upload
              class="avatar-uploader"
              action="123"
              :show-file-list="false"
              accept="image/*"
              :before-upload="beforeThumbUpload"
            >
              <img
                v-if="thumbSrc"
                style="width: 100%"
                :src="thumbSrc"
                class="avatar"
              />
              <i
                v-else
                class="el-icon-plus avatar-uploader-icon"
                style="margin-top: 85px"
              ></i>
            </el-upload>
          </el-form-item>

          <el-form-item el-form-item label="内容详情" label-width="100px">
            <quill-editor v-model="addForm.content" ref="myQuillEditor">
            </quill-editor>
          </el-form-item>

          <el-form-item label-width="40%" style="margin-top: 20px">
            <el-button type="primary" @click="editReadNow">立即修改</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
//引入富文本组件
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

var COS = require("cos-js-sdk-v5");

import {
  AppointmentList,
  AppointmentType,
  AppointmentEdit,
  getAppointmentCosToken,
} from "@/api/Japanese_app/appointment.js";
export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      appointmentType: [],
      appointmentList: [],
      addDialog: false,
      addForm: {
        type: "",
        content: "",
        thumb: "",
        label: [],
      },
      thumbSrc: "",
    };
  },
  created() {
    this.getAppointmentType();
    this.getAppointmentList();
  },
  methods: {
    // 日语APP恰学约课分类
    getAppointmentType() {
      AppointmentType().then((res) => {
        this.appointmentType = res.data;
      });
    },
    //   获取约课列表
    getAppointmentList() {
      AppointmentList().then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.appointmentList = res.data;
      });
    },

    // 点击编辑图标
    editGoods(item) {
      this.addDialog = true;
      this.addForm = JSON.parse(JSON.stringify(item));
      this.thumbSrc = this.addForm.thumb_url;
    },

    // 添加题目选项
    addTopicOptions() {
      this.addForm.label.push({ value: "" });
    },

    // 上传缩略图
    beforeThumbUpload(file) {
      const isSize = file.size / 1024 / 1024 < 10;
      if (!isSize) {
        this.$message.error("上传缩略图不能超过 10MB!");
        return;
      }
      let that = this;
      getAppointmentCosToken({ suffix: file.name }).then((res) => {
        var cos = new COS({
          getAuthorization: function (options, callback) {
            callback({
              TmpSecretId: res.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.credentials.tmpSecretKey,
              XCosSecurityToken: res.data.credentials.sessionToken,
              StartTime: res.data.startTime,
              ExpiredTime: res.data.expiredTime,
              expiration: res.data.expiration,
              requestId: res.data.requestId,
            });
          },
        });
        cos.putObject(
          {
            Bucket: res.data.Bucket,
            Region: res.data.Region,
            Key: res.data.key,
            // ContentType:'multipart/form-data',
            Body: file, // 上传文件对象
            onProgress: function (progressData) {},
          },
          function (err, data) {
            if (data) {
              that.addForm.thumb = res.data.key;
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = (e) => {
                that.thumbSrc = e.target["result"]; // 获取文件装换后的Base64位文件
                that.$forceUpdate();
              };
            }
          }
        );
      });
    },

    // 去除空标签
    trimSpace(array) {
      for (var i = 0; i < array.length; i++) {
        if (array[i].value == "") {
          array.splice(i, 1);
          i = i - 1;
        }
      }
      return array;
    },

    // 点击立即修改
    editReadNow() {
      this.trimSpace(this.addForm.label);
      if (this.addForm.type == "") {
        this.$message.error("请选择类型");
        return;
      } else if (this.addForm.label.length == 0) {
        this.$message.error("请添加标签");
        return;
      } else if (this.addForm.thumb == "") {
        this.$message.error("请上传缩略图");
        return;
      } else if (this.addForm.content == "") {
        this.$message.error("请填写内容详情");
        return;
      }
      AppointmentEdit(this.addForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("修改约课成功");
        this.addDialog = false;
        this.getAppointmentList();
      });
    },

    // 弹窗关闭事件
    closed() {
      this.addForm = {
        type: "",
        content: "",
        thumb: "",
        label: [],
      };
      this.thumbSrc = "";
    },
  },
};
</script>

<style scoped lang="less">
.generate {
  float: right;
}
.el-tag {
  cursor: pointer;
}
::v-deep .ql-editor {
  min-height: 400px;
}
.thumb_box {
  width: 100%;
  height: 100%;

  img {
    width: 200px;
    height: 120px;
  }
}

.addtopic {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.add_box {
  width: 100%;
  margin-bottom: 20px;
}

.el-card {
  padding-bottom: 50px;
}
</style>
